/*! _theme-default.scss | Nephos | CSS Ninja */

/* ==========================================================================
Default theme variables 
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Variables
1. Shadow variables
=============================================================================
***/

/* ==========================================================================
0. Color Variables
========================================================================== */

$primary: #0b3f7e; // #0023ff;
$secondary: #00D1B2;
$accent: #7F00FF;

/* ==========================================================================
1. Shadow variables
========================================================================== */

//Base shadow
$base-shadow: rgba(0, 0, 0, 0.12);

//Primary box shadow
$primary-shadow-from: rgba(0, 35, 255, 0.42);
$primary-shadow-to: rgba(0, 35, 255, 0.2);
$primary-box-shadow:  0 14px 26px -12px $primary-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $primary-shadow-to !important;

//Secondary box shadow
$secondary-shadow-from: rgba(0, 209, 178, 0.42);
$secondary-shadow-to: rgba(0, 209, 178, 0.2);
$secondary-box-shadow:  0 14px 26px -12px $secondary-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $secondary-shadow-to !important;

//Accent box shadow
$accent-shadow-from: rgba(127, 0, 255, 0.42);
$accent-shadow-to: rgba(127, 0, 255, 0.2);
$accent-box-shadow:  0 14px 26px -12px $accent-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $accent-shadow-to !important;

/* ==========================================================================
1. Shadow variables
========================================================================== */
// $font-family-1: 'Raleway', sans-serif;
$font-family-1: 'Roboto', sans-serif;
$font-family-2: 'Alegreya Sans SC', sans-serif;