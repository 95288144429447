/*! _search.scss | Nephos | CSS Ninja */

/* ==========================================================================
Global Search Styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Search Overlay
1. Search Input
=============================================================================
***/

/* ==========================================================================
0. Search Overlay
========================================================================== */

.search-overlay {
    position: fixed;
    top: -100px;
    left: -100px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: rgba(255,255,255,0.9);
    transition: all .4s;
    transform: scale(1);
    z-index: 3;
    &.is-active {
        transform: scale(50);
    }
}

/* ==========================================================================
1. Search Input
========================================================================== */

.search-input-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 4;
    &.is-desktop {
        left: 80px;
        width: calc(100% - 80px);
    }
    //field wrapper
    .field {
        position: relative;
        top: 25%;
        width: 100%;
        padding: 0 18%;
    }
    //control wrapper
    .control {
        position: relative;
        width: 100%;
        margin: 0 auto;
        opacity: 0;
        transform: translateY(100%);
        transition: all .3s;
        //input
        input {
            width: 100%;
            height: 155px;
            padding-right: 75px;
            border-left: none;
            border-right: none;
            border-top: none;
            border-radius: 0;
            border-bottom: 3px solid $primary;
            background: transparent;
            font-size: 5rem;
            font-weight: 200;
            font-family: $font-family-2;
            text-transform: uppercase;
            padding-bottom: 10px;
            &:focus {
                outline: none !important;
            }
        }
        //help text under input
        .search-help {
            display: block;
            color: $primary;
            font-size: 1.3rem;
            padding-top: 20px;
        }
        //clear input icon
        #clear-search {
            display: block;
            position: absolute;
            top: 64px;
            right: 16px;
            cursor: pointer;
            svg {
                width: 42px;
                height: 42px;
                stroke: $primary;
                stroke-width: 1px;
            }
        }
        //pushing the input to the top when active
        &.is-active  {
            opacity: 1;
            transform: translateY(0);
        }
    }
    //hide clear icon
    .control input:not(:valid) ~ #clear-search {
        display: none;
    }
}